<template>
  <div class="ma-6 pb-6">
    <h2 class="title">{{ $t("silos.createByImeiTitle") }}</h2>
    <v-text-field
      :label="$t('silos.addByImei')"
      v-model="imei"
      prepend-inner-icon="mdi-silo"
    ></v-text-field>

    <v-alert border="left" color="red lighten-2" dark v-if="errorMessage">
      {{ $t("silos.errors.imeiNotFound") }}
    </v-alert>

    <v-btn @click="save" color="primary">{{ $t("common.add") }}</v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SiloSupplierInvite",

  data() {
    return { imei: "", errorMessage: false };
  },

  methods: {
    ...mapActions("silos", ["addSiloByImei"]),

    async save() {
      this.errorMessage = false;
      var result = await this.addSiloByImei(this.imei);
      if (result) this.$router.push("/silos");

      this.errorMessage = true;
    },
  },
};
</script>