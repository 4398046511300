<template>
  <div>
    <silo-form v-if="!supplierMode" />
    <silo-supplier-invite v-else />
    <v-divider v-if="isSupplier"></v-divider>
    <div
      class="d-flex flex-column align-center justify-center pa-4"
      v-if="isSupplier"
    >
      <v-btn @click="supplierMode = !supplierMode">
        {{
          supplierMode ? $t("silos.changeMode") : $t("silos.createByImeiTitle")
        }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SiloForm from "./SiloForm.vue";
import SiloSupplierInvite from "./SiloSupplierInvite.vue";

export default {
  name: "SiloCreate",

  data() {
    return {
      supplierMode: false,
    };
  },

  computed: {
    ...mapGetters("users", ["isSupplier"]),
  },

  created() {
    if (this.isSupplier) this.supplierMode = true;
  },

  components: {
    SiloForm,
    SiloSupplierInvite,
  },
};
</script>